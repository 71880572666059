import { useBranchByCodeQuery } from "api/useBranch";
import { useCustomerReservationEchartQuery } from "api/useCustomerEcharts";
import { useReservationQuery } from "api/useReservation";
import { ROUTE } from "constants/paths";
import MenuHeader from "layouts/MainLayout/MenuHeader";
import CustomerMessage from "pages/Customer/components/CustomerMessage";
import FaceScanData from "pages/Customer/components/FaceScanData";
import InfoTab from "pages/Customer/components/InfoTab";
import MyProposalContent from "pages/CustomerPages/components/MyProposalContent";
import ShareProposal from "pages/CustomerPages/components/ShareProposal";
import StoreInformation from "pages/CustomerPages/components/StoreInformation";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Container from "components/Container";
import Loading from "components/Loading";

const MyProposal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { branchCode, reservationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const urlTab = searchParams.get("tab");
  const { data: reservation } = useReservationQuery(reservationId || "1");
  const [echartId, setEchartId] = useState<string | undefined>(undefined);
  const [echartImageExists, setEchartImageExists] = useState<boolean>(false);

  const { data: latestReservationData } = useReservationQuery(
    reservationId || ""
  );

  const branchQuery: any = useBranchByCodeQuery(branchCode || "");

  const handleTabClick = (index: number) => {
    if (urlTab === "message") {
      navigate(location.pathname, { replace: true });
    }
    setActiveTab(index);
  };

  const { data: echart, isLoading } = useCustomerReservationEchartQuery(
    reservation?.reservation?.customer_id || "",
    reservationId || ""
  );

  useEffect(() => {
    if (echart) {
      setEchartId(echart[0].id);
      setEchartImageExists(echart[0].image_existed);
    }
  }, [echart]);

  useEffect(() => {
    if (urlTab === "message") {
      setActiveTab(2);
    }
  }, [urlTab]);

  return (
    <>
      <Container className="pb-0">
        <MenuHeader
          headerLabel={"マイカルテ"}
          leftLabel={"戻る"}
          leftLink={ROUTE.CUSTOMER_MYPAGE.replace(
            ":branchCode",
            branchCode || ""
          )}
        />
        <InfoTab
          onTabClick={handleTabClick}
          activeTab={activeTab}
          tab1="プレカルテ"
          tab2="予約"
          tab3="メッセージ"
          tab4="店舗情報"
          tab5={
            branchQuery?.data?.branch?.show_face_balance_report
              ? "診断データ"
              : null
          }
        />
        {activeTab === 0 && <MyProposalContent />}
        {activeTab === 1 && <ShareProposal />}
      </Container>
      {activeTab === 2 && reservation?.reservation?.customer_id && (
        <CustomerMessage
          customerId={reservation?.reservation?.customer_id}
          showMess
          branchId={reservation?.reservation?.branch_id}
        />
      )}

      {activeTab === 3 && (
        <StoreInformation branchId={reservation?.reservation?.branch_id} />
      )}
      {activeTab === 5 &&
        branchQuery?.data?.branch?.show_face_balance_report && (
          <div className="bg-gray-100 max-w-md  mx-auto md:w-auto">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {echartId && echartImageExists && (
                  <FaceScanData
                    customer_id={reservation?.reservation?.customer_id}
                    echart_id={echartId}
                    reservationAllData={latestReservationData.reservation}
                  />
                )}
              </>
            )}
          </div>
        )}
    </>
  );
};

export default MyProposal;
